import useAction from "./Actions/hooks/useAction";
import Pinpoint from "./Pinpoint";

interface HotspotProps {
  hotspot: PartialHotspot;
}

const Hotspot = ({ hotspot: { pinpoint, action } }: HotspotProps) => {
  const [active, trigger] = useAction(action);

  return <Pinpoint pinpoint={pinpoint || {}} onClick={() => trigger()} />;
};

export default Hotspot;
