import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useState } from "react";
import { BackSide, Texture, TextureLoader } from "three";
import useStore from "../../../store";
import Hotspots from "../components/Hotspots";
import Loading from "../components/Loading";
import Controls from "./components/Controls";

interface PanoramaProps {
  panorama: PartialPanorama;
}

const Panorama = ({ panorama: { hotspots, camera } }: PanoramaProps) => {
  const panoramaURL = useStore((state) => state.panoramaURL);
  const [texture, setTexture] = useState<Texture>();

  useEffect(() => {
    if (panoramaURL) {
      new TextureLoader().load(panoramaURL, setTexture);
    }
  }, [panoramaURL]);

  return (
    <Canvas>
      <Controls position={camera || [0.001, 0, 0]} />
      {/* TODO: Replace console log with Devtool component */}
      <Suspense fallback={<></>}>
        <mesh
          scale={[-1, 1, 1]}
          onPointerDown={({ point }) =>
            console.log("Click position:", point.toArray())
          }
        >
          <sphereBufferGeometry args={[10, 60, 40]} />
          <meshBasicMaterial map={texture} side={BackSide} />
        </mesh>
      </Suspense>
      <Hotspots hotspots={hotspots} />
    </Canvas>
  );
};

export default Panorama;
