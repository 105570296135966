import create from "zustand";
import EventEmitter from "eventemitter3";

interface StoreState {
  events: EventEmitter;

  panorama: PartialPanorama;
  setPanorama: (panorama: PartialPanorama) => void;
  updatePanorama: (panorama: PartialPanorama) => void;

  panoramaURL: string;
  setPanoramaURL: (url: string) => void;

  camera_pos: [number, number, number];
  setCameraPos: (camera_pos: [number, number, number]) => void;

  side_open: boolean;
  setSideOpen: (side_open: boolean) => void;

  mode: "preview" | "edit";
  setMode: (mode: "preview" | "edit") => void;
}

const useStore = create<StoreState>((set) => ({
  events: new EventEmitter(),
  panorama: {
    toggleLabels: false,
    // HACK: [0,0,0] locks camera
    camera: [0.001, 0, 0],
    title_color: "#ffffff",
  },
  updatePanorama: (panorama) =>
    set((state) => ({ panorama: { ...state.panorama, ...panorama } })),
  setPanorama: (panorama) => set(() => ({ panorama })),
  panoramaURL: "",
  setPanoramaURL: (url) => set(() => ({ panoramaURL: url })),
  camera_pos: [0, 0, 0],
  setCameraPos: (camera_pos) => set(() => ({ camera_pos })),
  side_open: false,
  setSideOpen: (side_open) => set(() => ({ side_open })),
  mode: "edit",
  setMode: (mode) => set(() => ({ mode })),
}));

export default useStore;
