import { useQuery } from "react-query";
import DLResource from "../../../utils/DLResource";

const useGpxQuery = (uuid: string) => {
  return useQuery("src", async () => {
    if (uuid.startsWith("local-")) {
      return await (await fetch(`/json/model/${uuid.slice(6)}.json`)).json();
    }
    const dlResource = await DLResource.get(uuid);
    console.log(dlResource.url.href);
    const fakeIIIF = await (await fetch(dlResource.url.href)).json();
    return (
      fakeIIIF.sequences[0].canvases[0].thumbnail.service["@id"] +
      "/info.json" +
      dlResource.url.search
    );
  });
};

export default useGpxQuery;
