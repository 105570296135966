import { useCallback, useEffect, useState } from "react";
import ColorPicker from "../../UI/ColorPicker";
import TextInput from "../../UI/TextInput";

interface ResourceInfoInputProps {
  value?: PartialResourceInfo;
  onChange?: (data: PartialResourceInfo) => void;
}

const ResourceInfoInput = ({ value, onChange }: ResourceInfoInputProps) => {
  const [info, setInfo] = useState<PartialResourceInfo>(value || {});

  const handleChange = useCallback(
    (update: PartialResourceInfo) => {
      const updated: PartialResourceInfo = { ...info, ...update };
      setInfo(updated);
      onChange?.(updated);
    },
    [onChange, info],
  );

  useEffect(() => {
    setInfo(value || {});
  }, [value]);

  return (
    <>
      <TextInput
        label="Titolo"
        value={info.title}
        onChange={(title) => handleChange({ title })}
        required
      />
      <TextInput
        label="Occhiello"
        value={info.strapline}
        onChange={(strapline) => handleChange({ strapline })}
        required
      />
      <ColorPicker
        value={info.title_color}
        onChange={(title_color) => handleChange({ title_color })}
      />
      <TextInput
        label="Descrizione"
        textarea
        value={info.description}
        onChange={(description) => handleChange({ description })}
        required
      />
    </>
  );
};

export default ResourceInfoInput;
