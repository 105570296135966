import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useState } from "react";
import IndexedArray from "../../../../utils/IndexedArray";
import Button from "../../../UI/Button";
import ResourceLinkInput from "../ResourceLinkInput";

interface ResourceLinkListInputProps {
  value?: Array<PartialResourceLink>;
  onChange?: (value: Array<PartialResourceLink>) => void;
}

const ResourceLinkListInput = ({
  value,
  onChange,
}: ResourceLinkListInputProps) => {
  const [list, setList] = useState<IndexedArray<PartialResourceLink>>([]);
  useEffect(() => {
    setList(new IndexedArray(value ?? []));
  }, [value]);

  const handleChange = useCallback(
    (list: IndexedArray<PartialResourceLink>) => {
      const clone = [...list];
      setList(clone);
      onChange?.(clone);
    },
    [onChange],
  );

  const addLink = () => {
    handleChange(new IndexedArray([...list, {}]));
  };

  const updateLink = (link: PartialResourceLink, index: number) => {
    list.splice(index, 1, link);
    handleChange(list);
  };

  const removeLink = (index: number) => {
    list.splice(index, 1);
    handleChange(list);
  };

  return (
    <div className="flex flex-col gap-2">
      {list.map((link, i) => (
        <div
          key={`input-${link.uuid}`}
          className="flow-row flex items-end justify-between gap-2"
        >
          <ResourceLinkInput
            className="grow"
            value={link}
            onChange={(link) => updateLink(link, i)}
          />
          <Button className="hover:bg-red-400" onClick={() => removeLink(i)}>
            <TrashIcon className="w-5" />
          </Button>
        </div>
      ))}
      <Button onClick={() => addLink()}>
        <PlusIcon className="w-5" />
      </Button>
    </div>
  );
};

export default ResourceLinkListInput;
