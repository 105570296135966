import Pinpoint from "./Pinpoint";

interface HotspotProps {
  hotspot: PartialHotspot;
}

const Hotspot = ({ hotspot: { pinpoint, action } }: HotspotProps) => {
  if (!pinpoint) return null;

  return <Pinpoint pinpoint={pinpoint} />;
};

export default Hotspot;
