import { useState } from "react";

const useLinkAction = (action: LinkAction) => {
  // TODO: use react-router navigation
  // const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const trigger = () => {
    setActive(true);
    // navigate(action.to);
    //window.location.href = action.to;
  };
  return [active, trigger] as const;
};

export default useLinkAction;
