import { OrbitControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import * as THREE from "three";

const ZOOM_START = 40;
const ZOOM_MIN = 20;
const ZOOM_MAX = 75;

interface ControlsProps {
  position?: THREE.Vector3Tuple;
  onMove?: (position: THREE.Vector3Tuple) => void;
}

const Controls = ({ position, onMove }: ControlsProps) => {
  const { events } = useThree();
  const { camera }: { camera: THREE.PerspectiveCamera } = useThree();

  useEffect(() => {
    camera.fov = ZOOM_START;
    if (position) {
      camera.position.set(...position);
    }
    camera.updateProjectionMatrix();
  }, [camera, position]);

  useEffect(() => {
    if (events.handlers) {
      const zoom = ({ deltaY }: WheelEvent) => {
        const fov = camera.fov + deltaY * 0.05;
        camera.fov = THREE.MathUtils.clamp(fov, ZOOM_MIN, ZOOM_MAX);
        camera.updateProjectionMatrix();
      };
      events.handlers.onWheel = zoom as EventListener;
    }
  }, [events.handlers, camera]);

  return (
    <OrbitControls
      enablePan={false}
      enableZoom={false}
      enableRotate={true}
      dampingFactor={0.1}
      rotateSpeed={-0.25}
      makeDefault
      onEnd={() => onMove?.(camera.position.toArray())}
    />
  );
};

export default Controls;
