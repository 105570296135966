import { Html } from "@react-three/drei";
import HotspotIcon from "../../../../../icons/hotspot";
import Label from "./Label";

interface PinpointProps {
  pinpoint: PartialPinpoint;
  onClick?: () => void;
}

const Pinpoint = ({
  pinpoint: { position, icon, label, dimension = "11" },
  onClick,
}: PinpointProps) => {
  return (
    <Html
      center
      position={position || [0, 0, 0]}
      zIndexRange={[1000, 0]}
      wrapperClass="hover:!z-[1001]"
    >
      <div className="relative">
        <div
          className={`flex w-${dimension} h-${dimension} group flex-row items-end drop-shadow-2xl`}
        >
          {icon && (
            <HotspotIcon
              icon={icon}
              className="w-full cursor-pointer text-white transition duration-200 ease-in-out group-hover:scale-125"
              onClick={() => onClick?.()}
            />
          )}
          {label && (
            <div
              className={`${
                icon ? "left-full" : ""
              } pointer-events-none absolute group-hover:inline`}
            >
              <Label text={label} />
            </div>
          )}
        </div>
      </div>
    </Html>
  );
};

export default Pinpoint;
