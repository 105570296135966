import { useEffect, useState } from "react";
import IndexedArray from "../../../utils/IndexedArray";
import Hotspot from "./Hotspot/Hotspot";
import Pinpoint from "./Hotspot/Pinpoint";

interface HotspotsProps {
  hotspots?: PartialHotspot[];
}

const Hotspots = ({ hotspots }: HotspotsProps) => {
  const [list, setList] = useState<IndexedArray<PartialHotspot>>([]);
  useEffect(() => {
    setList(new IndexedArray(hotspots ?? []));
  }, [hotspots]);

  return (
    <>
      {list?.map((hotspot) => {
        if (!hotspot.pinpoint) {
          return null;
        }

        const key = `hotspot-${hotspot.uuid}`;

        if (!hotspot.action) {
          return <Pinpoint key={key} pinpoint={hotspot.pinpoint} />;
        }

        return <Hotspot key={key} hotspot={hotspot} />;
      })}
    </>
  );
};

export default Hotspots;
