import React, { useCallback } from "react";
import useStore from "../../store";
import Toggle from "../UI/Toggle";
import CameraButton from "./CameraButton";
import SaveButton from "./SaveButton";
import ResourceInfoInput from "./ResourceInfoInput";
import ResourceLinkListInput from "./ResourceInfoInput/ResourceLinkListInput";
import LoadButton from "./LoadButton";
import HotspotListInput from "./HotspotListInput";
import Sidebar from "../UI/Sidebar";
import LoadUUID from "./LoadUUID";

interface SideProps {
  className?: string;
}

const Side = ({ className }: SideProps) => {
  const setPanorama = useStore((state) => state.setPanorama);
  const updatePanorama = useStore((state) => state.updatePanorama);
  const panorama = useStore((state) => state.panorama);
  const handleChange = useCallback(
    (update: PartialPanorama) => {
      updatePanorama({ ...panorama, ...update });
    },
    [updatePanorama, panorama],
  );

  const Load = (panorama: PartialPanorama) => {
    setPanorama(panorama);
  };

  const handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    let filename = "panorama";
    if(panorama.title){
      filename = panorama.title.replace(/ /g,"_");
    }
     if(panorama.src){
      filename += "_" + panorama.src;
    }
    
    e.preventDefault();
    DownloadFile(filename, GetPanoramaData());
  };

  const DownloadFile = (fileName: string, data: string) => {
    const element = document.createElement("a");
    const file = new Blob([data], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.json`;
    element.click();
  };

  const GetPanoramaData = () => {
    return JSON.stringify(panorama);
  };

  return (
    <Sidebar
      className={`${className} overflow-y-auto bg-stone-800 to-stone-300 p-4`}
    >
      <form className={`flex flex-col gap-3`} onSubmit={(e) => handleSubmit(e)}>
        <LoadUUID value={panorama} onChange={(src) => handleChange(src)} />
        <ResourceInfoInput
          onChange={(info) => handleChange(info)}
          value={panorama}
        />
        <Sidebar.PanelButton label="Resource Links">
          <h2 className="text-2xl text-white">Resource Links</h2>
          <br />
          <ResourceLinkListInput
            onChange={(links) => handleChange({ links })}
            value={panorama.links}
          />
        </Sidebar.PanelButton>
        <CameraButton
          onClick={(camera) => handleChange({ camera })}
          value={panorama.camera}
        />
        <Toggle
          onChange={(toggleLabels) => handleChange({ toggleLabels })}
          checked={panorama.toggleLabels}
        >
          Etichette visibili
        </Toggle>
        <Sidebar.PanelButton label="Hotspots">
          <h2 className="text-2xl text-white">Hotspots</h2>
          <br />
          <HotspotListInput
            onChange={(hotspots) => handleChange({ hotspots })}
            value={panorama.hotspots}
          />
        </Sidebar.PanelButton>
        <SaveButton />
        <LoadButton
          onLoad={(panorama) => {
            Load({ ...panorama });
          }}
        />
      </form>
    </Sidebar>
  );
};

export default Side;
