import { Editor } from "@tiptap/react";
import MenuButton from "./MenuButton";

interface MenuProps {
  editor: Editor | null;
}

const Menu = ({ editor }: MenuProps) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="flex gap-1 rounded-t bg-stone-600 p-1">
      <MenuButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        className="font-bold"
        active={editor.isActive("bold")}
      >
        B
      </MenuButton>
      <MenuButton
        onClick={() => {
          editor.chain().focus().toggleItalic().run();
          console.log("i");
        }}
        className="italic"
        active={editor.isActive("italic")}
      >
        I
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className="underline"
        active={editor.isActive("underline")}
      >
        U
      </MenuButton>
    </div>
  );
};

export default Menu;
