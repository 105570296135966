import { useCallback, useEffect, useState } from "react";
import IconSelect from "../../../UI/IconSelect";
import TextInput from "../../../UI/TextInput";

interface ResourceLinkInputProps {
  value?: PartialResourceLink;
  onChange?: (resource_link: ResourceLink) => void;
  className?: string;
}

const ResourceLinkInput = ({
  value,
  onChange,
  className,
}: ResourceLinkInputProps) => {
  const [link, setLink] = useState<PartialResourceLink>(value || {});

  useEffect(() => {
    setLink(value || {});
  }, [value]);

  const handleChange = useCallback(
    (update) => {
      const updated = { ...link, ...update };
      setLink(updated);
      onChange?.(updated);
    },
    [onChange, link],
  );

  return (
    <div className={`${className} flex flex-col gap-1`}>
      <div className="flex flex-row items-end gap-2">
        <IconSelect
          value={link?.icon}
          onChange={(icon) => handleChange({ icon })}
        />
        <TextInput
          placeholder="Label"
          className="grow"
          required
          value={link?.label}
          onChange={(label) => handleChange({ label })}
        />
      </div>
      <TextInput
        placeholder="href"
        onChange={(href) => handleChange({ href })}
        required
        value={link?.href}
      />
    </div>
  );
};

export default ResourceLinkInput;
