import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import {
  Children,
  Fragment,
  isValidElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Option from "./Option";

interface IOption {
  label?: ReactNode;
  value: string;
}

type OptionType = string | IOption;

interface SelectProps {
  className?: string;
  children?: ReactNode;
  value?: OptionType;
  onChange?: (value: string) => void;
}

const Select = ({ className, children, value = "", onChange }: SelectProps) => {
  const [selected, setSelected] = useState<OptionType>(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const options = useMemo(() => {
    const options: { [key: string]: ReactNode } = {};
    Children.forEach(children, (child) => {
      if (isValidElement(child) && "children" in child.props) {
        let value: string = child.props.children as string;
        if ("value" in child.props) {
          value = child.props.value;
        }

        options[value] = child.props.children;
      }
    });
    return options;
  }, [children]);

  const handleChange = useCallback(
    (value) => {
      setSelected(value);
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <div className={`${className} relative`}>
      <Listbox value={selected} onChange={(value) => handleChange(value)}>
        <Listbox.Button className="flex w-full flex-row items-center justify-between rounded border border-stone-600 py-2 pl-2 pr-1">
          <span>{options[selected as string] || selected}</span>
          <span className="pointer-events-none ml-1 flex items-center">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute top-full z-10 mt-1 w-full rounded border border-stone-600 bg-stone-800">
            {children}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

Select.Option = Option;

export default Select;
