import { Switch } from "@headlessui/react";
import { ReactNode, useState, useEffect } from "react";

interface ToggleProps {
  children?: ReactNode;
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

const Toggle = ({ children, checked = false, onChange }: ToggleProps) => {
  const [value, setValue] = useState<boolean>(checked);

  const handleChange = (value: boolean) => {
    setValue(value);
    onChange?.(value);
  };

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  return (
    <div className="pointer-events-auto m-0 flex w-full justify-between">
      <Switch.Group>
        {children && (
          <Switch.Label className="flex items-center">{children}</Switch.Label>
        )}
        <Switch
          checked={value}
          onChange={(value) => handleChange(value)}
          className={`${
            value ? "bg-blue-400" : "bg-gray-400"
          } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span
            className={`${
              value ? "translate-x-5" : "translate-x-0"
            } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
};

export default Toggle;
