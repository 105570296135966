import { useCallback, useEffect, useState } from "react";
import IconSelect from "../../../../../../../UI/IconSelect";
import TextInput from "../../../../../../../UI/TextInput";
import ActionInput from "../../../ActionInput";

interface ActionListItemInputProps {
  value?: PartialListActionItem;
  onChange?: (action: PartialListActionItem) => void;
}

const ActionListItemInput = ({ value, onChange }: ActionListItemInputProps) => {
  const [item, setItem] = useState<PartialListActionItem>(value || {});
  useEffect(() => {
    setItem(value || {});
  }, [value]);

  const handleChange = useCallback(
    (update: PartialListActionItem) => {
      const updated: PartialListActionItem = { ...item, ...update };
      setItem(updated);
      onChange?.(updated);
    },

    [onChange, item],
  );

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row gap-1">
        <IconSelect
          value={item.icon}
          onChange={(icon) => handleChange({ icon })}
        />
        <TextInput
          value={item.label}
          onChange={(label) => handleChange({ label })}
        />
      </div>
      <ActionInput
        value={item.action}
        onChange={(action) => handleChange({ action })}
      />
    </div>
  );
};

export default ActionListItemInput;
