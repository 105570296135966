import { useCallback, useEffect, useState } from "react";
import IconSelect from "../../../../UI/IconSelect";
import Select from "../../../../UI/Select";
import TextInput from "../../../../UI/TextInput";
import PanoramaPositionButton from "../PanoramaPositionButton";

const DEFAULT_VALUE: PartialPinpoint = {
  icon: "info",
  dimension: "10",
  label: "",
};

interface PinpointInputProps {
  value?: PartialPinpoint;
  onChange?: (pinpoint: PartialPinpoint) => void;
}

const PinpointInput = ({ value, onChange }: PinpointInputProps) => {
  const [pinpoint, setPinpoint] = useState<PartialPinpoint>(value || {});
  useEffect(() => {
    setPinpoint(value || DEFAULT_VALUE);
  }, [value]);

  const handleChange = useCallback(
    (update: PartialPinpoint) => {
      const updated: PartialPinpoint = { ...pinpoint, ...update };
      setPinpoint(updated);
      onChange?.(updated);
    },
    [onChange, pinpoint],
  );

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row gap-1">
        <PanoramaPositionButton
          onChange={(position) => handleChange({ position })}
        />
        <IconSelect
          value={pinpoint?.icon}
          onChange={(icon) => handleChange({ icon })}
        />
        <Select
          value={pinpoint?.dimension || "11"}
          onChange={(dimension) => handleChange({ dimension })}
          className="grow"
        >
          <Select.Option value="8">X-Small</Select.Option>
          <Select.Option value="10">Small</Select.Option>
          <Select.Option value="14">Medium</Select.Option>
          <Select.Option value="20">Large</Select.Option>
          <Select.Option value="24">X-Large</Select.Option>
        </Select>
      </div>
      <TextInput
        value={pinpoint?.label}
        onChange={(label) => handleChange({ label })}
      />
    </div>
  );
};

export default PinpointInput;
