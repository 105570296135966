import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useState } from "react";
import Button from "../../UI/Button";
import HotspotInput from "./HotspotInput";
import IndexedArray from "../../../utils/IndexedArray";

interface HotspotListInputProps {
  value?: Array<PartialHotspot>;
  onChange?: (list: Array<PartialHotspot>) => void;
}

const HotspotListInput = ({ value, onChange }: HotspotListInputProps) => {
  const [list, setList] = useState<IndexedArray<PartialHotspot>>([]);
  useEffect(() => {
    setList(new IndexedArray(value ?? []));
  }, [value]);

  const handleChange = useCallback(
    (list: IndexedArray<PartialHotspot>) => {
      const clone = [...list];
      setList(clone);
      onChange?.(clone);
    },
    [onChange],
  );

  const updateItem = (hotspot: PartialHotspot, index: number) => {
    list?.splice(index, 1, hotspot);
    handleChange(list);
  };

  const removeItem = (index: number) => {
    list?.splice(index, 1);
    handleChange(list);
  };

  const addItem = () => {
    handleChange(new IndexedArray([...list, {}]));
  };

  return (
    <div className="flex flex-col gap-2">
      {list?.map((hotspot, i) => (
        <div
          key={`input-${hotspot!.uuid}`}
          className="flex flex-row items-end justify-between gap-2"
        >
          <HotspotInput
            className="grow"
            value={hotspot}
            onChange={(hotspot) => updateItem(hotspot, i)}
          />
          <Button className="hover:bg-red-400" onClick={() => removeItem(i)}>
            <TrashIcon className="w-5" />
          </Button>
        </div>
      ))}
      <Button onClick={() => addItem()}>
        <PlusIcon className="w-5" />
      </Button>
    </div>
  );
};

export default HotspotListInput;
