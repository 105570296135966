import { useEffect, useState, useCallback } from "react";
import InputMask from "react-input-mask";

const regexUUID =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const u = /[a-f0-9]/i;
const mask = [
  u,
  u,
  u,
  u,
  u,
  u,
  u,
  u,
  "-",
  u,
  u,
  u,
  u,
  "-",
  u,
  u,
  u,
  u,
  "-",
  u,
  u,
  u,
  u,
  "-",
  u,
  u,
  u,
  u,
  u,
  u,
  u,
  u,
  u,
  u,
  u,
  u,
];

interface UUIDInputProps {
  label?: string;
  value?: string;
  onChange?: (uuid: string) => void;
  required?: boolean;
}
const UUIDInput = ({
  label = "UUID",
  value = "",
  onChange,
  required = false,
}: UUIDInputProps) => {
  const [uuid, setUUID] = useState<string>(value);
  const [showError, setShowError] = useState<boolean>(false);

  const handleChange = useCallback(
    (value) => {
      setUUID(value);
      onChange?.(value);
      if (regexUUID.test(value)) {
        setShowError(false);
      }
    },
    [onChange],
  );

  const checkUUID = (value: string) => {
    setShowError(false);
    if (!regexUUID.test(value)) {
      setShowError(true);
    }
  };

  useEffect(() => {
    setUUID(value);
  }, [value]);

  return (
    <>
      <label className="flex items-baseline gap-2">
        {label}
        <InputMask
          placeholder="UUID"
          mask={mask}
          className="grow rounded border border-stone-600 bg-stone-800 px-3 py-1"
          value={uuid}
          onChange={({ target }) => handleChange(target.value)}
          onBlur={({ target }) => checkUUID(target.value)}
          required={required}
        ></InputMask>
      </label>
      {showError && (
        <label className="text-right text-xs text-red-500">
          Formato UUID non valido
        </label>
      )}
    </>
  );
};

export default UUIDInput;
