import { FlagIcon } from "@heroicons/react/solid";
import { useState } from "react";
import useStore from "../../../../../store";
import Button from "../../../../UI/Button";

interface PinpointButtonProps {
  onChange?: (position: [number, number, number]) => void;
}

const PanoramaPositionButton = ({ onChange }: PinpointButtonProps) => {
  const events = useStore((state) => state.events);
  const [active, setActive] = useState<boolean>(false);

  const handleClick = () => {
    setActive(true);
    events.once("click", (position: [number, number, number]) => {
      onChange?.(position);
      setActive(false);
    });
  };

  return (
    <Button
      onClick={() => handleClick()}
      onActiveClick={() => setActive(false)}
      active={active}
      className="w-16"
    >
      <FlagIcon className="w-5" />
    </Button>
  );
};

export default PanoramaPositionButton;
