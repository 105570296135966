import { v4 as uuid } from "uuid";

// TODO Consider not using uuid, but simply internal incremental index
class IndexedArray<T> extends Array<Indexed<T>> {
  constructor(items?: Array<T> | IndexedArray<T>) {
    // !HACK fix to make map work
    if (typeof items === "number") {
      super(items as any);
    } else {
      super(...IndexedArray.toIndexedArray(items));
    }
  }

  push(...items: T[]): number {
    return super.push(...IndexedArray.toIndexedArray(items));
  }

  splice(start: number, deleteCount: number, ...items: T[]): IndexedArray<T> {
    return super.splice(
      start,
      deleteCount,
      ...IndexedArray.toIndexedArray(items),
    );
  }

  private static isIndexed<T>(item: T): item is Indexed<T> {
    return "uuid" in item;
  }

  private static toIndexed<T>(item: T): Indexed<T> {
    return IndexedArray.isIndexed(item) ? item : { ...item, uuid: uuid() };
  }

  private static toIndexedArray<T>(items: T[] = []): Array<Indexed<T>> {
    return items.map(IndexedArray.toIndexed);
  }
}

export default IndexedArray;
