import { BlockPicker, ColorResult } from "react-color";
import { useState, useEffect } from "react";
import TextInput from "../TextInput";

const colorList = [
  "#9c9770",
  "#BCB587",
  "#7f7c5c",
  "#625f3e",
  "#D1B878",
  "#F2E4C1",
  "#F1D896",
  "#C9AD64",
  "#B38A5E",
  "#E5BD92",
  "#D19D66",
  "#83928d",
  "#cee0da",
];

interface ColorPickerProps {
  colors?: string[];
  value?: string;
  onChange?: (color: string) => void;
}

const ColorPicker = ({
  colors = colorList,
  value = "#ffffff",
  onChange,
}: ColorPickerProps) => {
  const [color, setColor] = useState<string>(value);
  const [open, setOpen] = useState<boolean>(false);

  const handleChangeComplete = (color: ColorResult) => {
    setColor(color.hex);
    onChange?.(color.hex);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <div>
      <div
        className="flex flex-row items-center justify-between"
        onClick={handleClick}
      >
        <TextInput
          label="Colore"
          className="mr-3 w-full"
          required
          disabled
          value={color}
        />
        <div
          className="h-5 w-5 border-2 border-black bg-transparent"
          style={{ backgroundColor: color }}
        ></div>
      </div>
      {open ? (
        <div className="absolute z-10">
          <div
            className="fixed top-0 left-0 right-0 bottom-0"
            onClick={handleClose}
          ></div>
          <BlockPicker
            className="mt-3"
            colors={colors}
            color={color}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
