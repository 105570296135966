import { useCallback, useEffect, useState } from "react";
import Select from "../../../../../UI/Select";
import GalleryModalInput from "./GalleryModalInput";
import GigapixelModalInput from "./GigapixelModalInput";
import ImageModalInput from "./ImageModalInput";
import TextModalInput from "./TextModalInput";

interface ModalActionInputProps {
  value?: ParitalModalAction;
  onChange?: (action: ParitalModalAction) => void;
}

const DEFAULT_VALUE: ParitalModalAction = {
  type: "modal",
};

const ModalActionInput = ({ value, onChange }: ModalActionInputProps) => {
  const [action, setAction] = useState<ParitalModalAction>(value || {});
  useEffect(() => {
    setAction(value || DEFAULT_VALUE);
  }, [value]);

  const handleChange = useCallback(
    (update: ParitalModalAction) => {
      const updated: ParitalModalAction = { ...action, ...update };
      setAction(updated);
      onChange?.(updated);
    },
    [onChange, action],
  );

  return (
    <>
      <Select
        value={action?.content?.type || "--- Modal Type ---"}
        onChange={(type) => handleChange({ content: { type } })}
      >
        <Select.Option value="text">Text</Select.Option>
        <Select.Option value="image">Image</Select.Option>
        <Select.Option value="gigapixel">Gigapixel</Select.Option>
        <Select.Option value="gallery">Gallery</Select.Option>
      </Select>
      {action?.content?.type === "text" && (
        <TextModalInput
          value={action?.content as TextModal}
          onChange={(content) => handleChange({ content })}
        />
      )}
      {action?.content?.type === "image" && (
        <ImageModalInput
          value={action?.content as ImageModal}
          onChange={(content) => handleChange({ content })}
        />
      )}
      {action?.content?.type === "gigapixel" && (
        <GigapixelModalInput
          value={action?.content as GigapixelModal}
          onChange={(content) => handleChange({ content })}
        />
      )}
      {action?.content?.type === "gallery" && (
        <GalleryModalInput
          value={action?.content as GalleryModal}
          onChange={(content) => handleChange({ content })}
        />
      )}
    </>
  );
};

export default ModalActionInput;
