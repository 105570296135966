import { useCallback, useEffect, useState } from "react";
import TextInput from "../../../../../../UI/TextInput";
import GalleryImageListInput from "./GalleryImageListInput/GalleryImageListInput";

interface GalleryModalInputProps {
  value?: PartialGalleryModal;
  onChange?: (content: PartialGalleryModal) => void;
}

const DEFAULT_VALUE: PartialGalleryModal = {
  type: "gallery",
  title: "",
  gallery: [],
};

const GalleryModalInput = ({ value, onChange }: GalleryModalInputProps) => {
  const [content, setContent] = useState<PartialGalleryModal>();
  useEffect(() => {
    setContent(value ?? DEFAULT_VALUE);
  }, [value]);

  const handleChange = useCallback(
    (update: PartialGalleryModal) => {
      const updated: PartialGalleryModal = { ...content, ...update };
      setContent(updated);
      onChange?.(updated);
    },
    [onChange, content],
  );

  return (
    <>
      <TextInput
        label="Title"
        value={content?.title}
        onChange={(title) => handleChange({ title })}
      />
      <GalleryImageListInput
        value={content?.gallery}
        onChange={(gallery) => handleChange({ gallery })}
      />
    </>
  );
};

export default GalleryModalInput;
