import HotspotIcon from "../../../../icons/hotspot";

interface ResourceLinkProps {
  link: PartialResourceLink;
}

const ResourceLink = ({ link: { label, icon } }: ResourceLinkProps) => {
  return (
    <span className="group pointer-events-auto my-2 flex cursor-pointer flex-row items-center justify-start font-body text-xl font-semibold text-white decoration-1 hover:underline">
      <HotspotIcon icon={icon} className="w-9" />
      <span className="pl-2 font-body text-lg font-medium leading-6 drop-shadow-md">
        {label}
      </span>
    </span>
  );
};

export default ResourceLink;
