import { useState, useEffect, useCallback } from "react";
import useStore from "../../../store";
import DLResource from "../../../utils/DLResource";
import Button from "../../UI/Button";
import UUIDInput from "../../UI/UUIDInput";

interface LoadUUIDProps {
  value?: PartialPanorama;
  onChange?: (src: PartialPanorama) => void;
}

const LoadUUID = ({ value, onChange }: LoadUUIDProps) => {
  const [uuid, setUUID] = useState(value || {});
  const setPanoramaURL = useStore((state) => state.setPanoramaURL);

  const handleClick = async () => {
    if (uuid.src) {
      const resource = await DLResource.get(uuid.src);
      setPanoramaURL(resource.buildURL("1/4096.jpg"));
    }
  };

  const handleChange = useCallback(
    (update: PartialPanorama) => {
      const updated: PartialPanorama = { ...uuid, ...update };
      setUUID(updated);
      onChange?.(updated);
    },
    [onChange, uuid],
  );

  useEffect(() => {
    setUUID(value || {});
  }, [value]);

  return (
    <>
      <UUIDInput
        required
        value={uuid.src}
        onChange={(src) => handleChange({ src })}
      />
      <Button onClick={() => handleClick()}>Load from UUID</Button>
    </>
  );
};

export default LoadUUID;
