import { useCallback, useState, useEffect } from "react";
import Editor from "../Editor";

interface TextInputProps {
  label?: string;
  value?: string;
  onChange?: (str: string) => void;
  textarea?: boolean;
  placeholder?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
}

const TextInput = ({
  label,
  value = "",
  onChange,
  textarea = false,
  placeholder,
  className,
  required = false,
  disabled = false,
}: TextInputProps) => {
  const [string, setString] = useState<string>(value);
  const handleChange = useCallback(
    (value) => {
      setString(value);
      onChange?.(value);
    },
    [onChange],
  );

  useEffect(() => {
    setString(value);
  }, [value]);

  return (
    <label className={`${className} flex flex-wrap items-baseline gap-2`}>
      {label}
      {textarea ? (
        <Editor
          onChange={(value) => handleChange(value)}
          className="w-full bg-stone-800"
          value={string}
        />
      ) : (
        <input
          type="text"
          className="grow rounded border border-stone-600 bg-stone-800 px-3 py-1 placeholder:text-stone-600"
          value={string}
          onChange={({ target }) => handleChange(target.value)}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />
      )}
    </label>
  );
};

export default TextInput;
