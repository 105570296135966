import Panorama from "./Panorama";
import { Suspense, useEffect } from "react";
import Overlay from "./components/Overlay";
import Modal from "../components/Hotspots/Modal";
import useStore from "../../../store";

const PanoramaViewer = () => {
  const panorama = useStore((state) => state.panorama);
  useEffect(() => {
    if (panorama) {
      let r = document.querySelector(":root");
      if (r instanceof HTMLElement) {
        r.style.setProperty("--period-color", panorama.title_color || "");
      }
    }
  }, [panorama]);

  return (
    <>
      {panorama && (
        <>
          <div className="gradientCanvas h-screen w-screen">
            <Suspense fallback={<></>}>
              <Panorama panorama={panorama} />
            </Suspense>
          </div>
          <Overlay panorama={panorama} />
        </>
      )}
      <Modal />
    </>
  );
};

export default PanoramaViewer;
