import HotspotIcon from "../../../../../icons/hotspot";
import useAction from "../../Hotspots/Actions/hooks/useAction";

interface ItemProps {
  action: ListActionItem;
}

const Item = ({ action: { icon, label, action } }: ItemProps) => {
  const [active, trigger] = useAction(action);

  return (
    <div
      className="action-list group pointer-events-auto mb-2.5 flex cursor-pointer flex-row items-start text-white decoration-1 hover:text-period-color hover:underline"
      onClick={() => trigger()}
    >
      <div className="icon flex w-5 justify-center">
        <HotspotIcon icon={icon} className="w-initial h-[18px]" />
      </div>
      <span className="ml-2 font-[Roboto] text-[17px] font-[400] ">
        {label}
      </span>
    </div>
  );
};
export default Item;
