import { useCallback, useEffect, useState } from "react";
import Select from "../../../../UI/Select";
import Sidebar from "../../../../UI/Sidebar";
import AudioActionInput from "./AudioActionInput";
import LinkActionInput from "./LinkActionInput";
import ListActionInput from "./ListActionInput";
import ModalActionInput from "./ModalActionInput";

interface ActionInputProps {
  value?: PartialAction;
  onChange?: (action: PartialAction) => void;
}

const isActionType = (value: string): value is ActionType =>
  ["audio", "link", "list", "modal"].includes(value);

const ActionInput = ({ value, onChange }: ActionInputProps) => {
  const [action, setAction] = useState<PartialAction>(value || {});
  useEffect(() => {
    setAction(value || {});
  }, [value]);

  const handleChange = useCallback(
    (update: PartialAction) => {
      const updated: PartialAction = { ...action, ...update };
      setAction(updated);
      onChange?.(updated);
    },
    [onChange, action],
  );

  return (
    <>
      <Select
        value={action?.type || "--- Action Type ---"}
        onChange={(type) => {
          if (isActionType(type)) {
            handleChange({ type });
          }
        }}
      >
        <Select.Option value="audio">Audio</Select.Option>
        <Select.Option value="link">Link</Select.Option>
        <Select.Option value="list">List</Select.Option>
        <Select.Option value="modal">Modale</Select.Option>
      </Select>
      {action?.type === "audio" && (
        <AudioActionInput
          value={action}
          onChange={(action) => handleChange(action)}
        />
      )}
      {action?.type === "link" && (
        <LinkActionInput
          value={action}
          onChange={(action) => handleChange(action)}
        />
      )}
      {action?.type === "list" && (
        <ListActionInput
          value={action}
          onChange={(action) => handleChange(action)}
        />
      )}
      {action?.type === "modal" && (
        <Sidebar.PanelButton label="Edit Modal">
          <ModalActionInput
            value={action}
            onChange={(action) => handleChange(action)}
          />
        </Sidebar.PanelButton>
      )}
    </>
  );
};

export default ActionInput;
