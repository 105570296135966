import { ReactComponent as NextIcon } from "../../../icons/next.svg";
import { ReactComponent as PrevIcon } from "../../../icons/prev.svg";
import useStore from "../../../store";

const ToggleSideButton = () => {
  const side_open = useStore((state) => state.side_open);
  const setSideOpen = useStore((state) => state.setSideOpen);
  const handleClick = () => {
    setSideOpen(!side_open);
  };

  return (
    <button
      type="button"
      className="pointer-events-auto flex h-10 w-10 cursor-pointer items-center justify-center rounded-r bg-stone-800 focus-visible:outline-none"
      onClick={handleClick}
    >
      {side_open ? <PrevIcon className="h-5" /> : <NextIcon className="h-5" />}
    </button>
  );
};

export default ToggleSideButton;
