import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useState } from "react";
import IndexedArray from "../../../../../../../utils/IndexedArray";
import Button from "../../../../../../UI/Button";
import ActionListItemInput from "./ListActionItemInput/ListActionItemInput";

interface ListActionListItemListInputProps {
  value?: Array<PartialListActionItem>;
  onChange?: (list: Array<PartialListActionItem>) => void;
}

const ListActionListInput = ({
  value,
  onChange,
}: ListActionListItemListInputProps) => {
  const [list, setList] = useState<IndexedArray<PartialListActionItem>>([]);
  useEffect(() => {
    setList(new IndexedArray(value ?? []));
  }, [value]);

  const handleChange = useCallback(
    (list: IndexedArray<PartialListActionItem>) => {
      const clone = [...list];
      setList(clone);
      onChange?.(clone);
    },
    [onChange],
  );

  const addItem = () => {
    handleChange(new IndexedArray([...list, {}]));
  };

  const updateItem = (item: PartialListActionItem, index: number) => {
    list?.splice(index, 1, item);
    handleChange(list);
  };

  const removeItem = (index: number) => {
    list?.splice(index, 1);
    handleChange(list);
  };

  return (
    <div className="flex flex-col gap-2">
      {list?.map((item, i) => (
        <div
          key={item.uuid}
          className="flex flex-row items-end justify-between gap-2"
        >
          <ActionListItemInput
            value={item}
            onChange={(item) => updateItem(item, i)}
          />
          <Button className="hover:bg-red-500" onClick={() => removeItem(i)}>
            <TrashIcon className="w-5" />
          </Button>
        </div>
      ))}
      <Button onClick={() => addItem()}>
        <PlusIcon className="w-5" />
      </Button>
    </div>
  );
};

export default ListActionListInput;
