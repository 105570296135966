import { ReactComponent as ThreeD } from "./3d.svg";
import { ReactComponent as Panorama } from "./panorama.svg";
import { ReactComponent as Audio } from "./audio.svg";
import { ReactComponent as Direction } from "./direction.svg";
import { ReactComponent as Animation } from "./animation.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as Camera } from "./camera.svg";
import { ReactComponent as Image } from "./image.svg";
import { ReactComponent as Down } from "./down.svg";
import { ReactComponent as IconVideo } from "./icon_video.svg";
import { ReactComponent as IconImage } from "./icon_image.svg";
import { ReactComponent as IconText } from "./icon_text.svg";
import { ComponentType, createElement } from "react";

interface Icon {
  className?: string;
  onClick?: () => void;
}

interface HotspotIconPros extends Icon {
  icon?: string;
}

export const Icons: { [key: string]: ComponentType } = {
  direction: Direction,
  "3d": ThreeD,
  panorama: Panorama,
  audio: Audio,
  animation: Animation,
  info: Info,
  camera: Camera,
  image: Image,
  down: Down,
  iconVideo: IconVideo,
  iconImage: IconImage,
  iconText: IconText,
};

const HotspotIcon = ({
  icon,
  className,
  onClick = () => null,
}: HotspotIconPros) => {
  if (icon && typeof Icons[icon] !== "undefined") {
    return createElement(Icons[icon] as ComponentType<Icon>, {
      className,
      onClick,
    });
  } else {
    return <Info className={className} />;
  }
};

export default HotspotIcon;
