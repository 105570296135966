import { useCallback, useEffect, useState } from "react";
import TextInput from "../../../../../UI/TextInput";
import ResourceLinkListInput from "../../../../ResourceInfoInput/ResourceLinkListInput";
import ListActionListInput from "./ListActionListInput";

interface ListActionInputProps {
  value?: PartialListAction;
  onChange?: (action: PartialListAction) => void;
}

const DEFAULT_VALUE: PartialListAction = {
  type: "list",
};

const ListActionInput = ({ value, onChange }: ListActionInputProps) => {
  const [action, setAction] = useState<PartialListAction>(value ?? {});
  useEffect(() => {
    setAction(value ?? DEFAULT_VALUE);
  }, [value]);

  const handleChange = useCallback(
    (update: PartialListAction) => {
      const updated: PartialListAction = { ...action, ...update };
      setAction(updated);
      onChange?.(updated);
    },
    [onChange, action],
  );

  return (
    <>
      <TextInput
        label="Title"
        value={action.title}
        onChange={(title) => handleChange({ title })}
      />
      <h2>Resource Links</h2>
      <ResourceLinkListInput
        value={action.links}
        onChange={(links) => handleChange({ links })}
      />
      <h2>Actions</h2>
      <ListActionListInput
        value={action.list}
        onChange={(list) => handleChange({ list })}
      />
    </>
  );
};

export default ListActionInput;
