import { useCallback, useEffect, useState } from "react";
import TextInput from "../../../../../../UI/TextInput";

interface TextModalInputProps {
  value?: PartialTextModal;
  onChange?: (content: PartialTextModal) => void;
}

const DEFAULT_VALUE: PartialTextModal = {
  type: "text",
  title: "",
  text: "",
};

const TextModalInput = ({ value, onChange }: TextModalInputProps) => {
  const [content, setContent] = useState<PartialTextModal>();
  useEffect(() => {
    setContent(value || DEFAULT_VALUE);
  }, [value]);

  const handleChange = useCallback(
    (update: PartialTextModal) => {
      const updated: PartialTextModal = { ...content, ...update };
      setContent(updated);
      onChange?.(updated);
    },
    [onChange, content],
  );

  return (
    <>
      <TextInput
        label="Title"
        value={content?.title}
        onChange={(title) => handleChange({ title })}
      />
      <TextInput
        label="Text"
        value={content?.text}
        onChange={(text) => handleChange({ text })}
        textarea
        required
      />
    </>
  );
};

export default TextModalInput;
