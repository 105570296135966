import { useCallback, useEffect, useState } from "react";
import ActionInput from "./ActionInput";
import PinpointInput from "./PinpointInput";

interface HotspotInputProps {
  className?: string;
  value?: PartialHotspot;
  onChange?: (hotspot: PartialHotspot) => void;
}

const HotspotInput = ({ className, value, onChange }: HotspotInputProps) => {
  const [hotspot, setHotspot] = useState<PartialHotspot>();
  useEffect(() => {
    setHotspot(value);
  }, [value]);

  const handleChange = useCallback(
    (update: PartialHotspot) => {
      const updated: PartialHotspot = { ...hotspot, ...update };
      setHotspot(updated);
      onChange?.(updated);
    },
    [onChange, hotspot],
  );

  return (
    <div className={className}>
      <PinpointInput
        value={hotspot?.pinpoint}
        onChange={(pinpoint) => handleChange({ pinpoint })}
      />
      <ActionInput
        value={hotspot?.action}
        onChange={(action) => handleChange({ action })}
      />
    </div>
  );
};

export default HotspotInput;
