import { Listbox, Transition } from "@headlessui/react";
import {
  ComponentType,
  createElement,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Icons } from "../../../icons/hotspot";
import { SelectorIcon } from "@heroicons/react/solid";

interface Icon {
  className?: string;
}

const DEFAULT_ICON = "info";

interface IconSelectProps {
  value?: string;
  onChange?: (icon: string) => void;
}

const IconSelect = ({ value = DEFAULT_ICON, onChange }: IconSelectProps) => {
  const [selected, setSelected] = useState<string>(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = useCallback(
    (value) => {
      setSelected(value);
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <div className="relative">
      <Listbox value={selected} onChange={handleChange}>
        <Listbox.Button className="flex flex-row items-center justify-start self-start rounded border border-stone-600 py-2 pl-2 pr-1">
          {createElement(Icons[selected] as ComponentType<Icon>, {
            className: "w-8 max-h-8",
          })}
          <span className="pointer-events-none ml-1 flex items-center ">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute top-full z-10 mt-1 flex w-80 flex-row flex-wrap justify-start rounded border border-stone-600 bg-stone-800 p-2">
            {Object.keys(Icons).map((key) => (
              <Listbox.Option
                key={key}
                value={key}
                className={({ selected }) =>
                  `flex  cursor-pointer select-none items-center justify-center rounded p-1 hover:bg-stone-600 ${
                    selected && "bg-stone-700"
                  }`
                }
              >
                {createElement(Icons[key] as ComponentType<Icon>, {
                  className: "w-11 max-h-11",
                })}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default IconSelect;
