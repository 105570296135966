import { useCallback, useEffect, useState } from "react";
import TextInput from "../../../../../UI/TextInput";
import UUIDInput from "../../../../../UI/UUIDInput";

interface AudioActionInputProps {
  value?: PartialAudioAction;
  onChange?: (action: PartialAudioAction) => void;
}

const AudioActionInput = ({ value, onChange }: AudioActionInputProps) => {
  const [action, setAction] = useState<PartialAudioAction>();
  useEffect(() => {
    setAction(value);
  }, [value]);

  const handleChange = useCallback(
    (update: PartialAudioAction) => {
      const updated: PartialAudioAction = { ...action, ...update };
      setAction(updated);
      onChange?.(updated);
    },
    [onChange, action],
  );

  return (
    <>
      <TextInput
        label="Titolo"
        value={action?.audio?.title}
        onChange={(title) =>
          handleChange({ audio: { ...action?.audio, title } })
        }
      />
      <UUIDInput
        value={action?.audio?.src}
        onChange={(src) => handleChange({ audio: { ...action?.audio, src } })}
        required
      />
    </>
  );
};

export default AudioActionInput;
