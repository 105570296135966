import { createContext, ReactNode } from "react";

interface ISidebarContext {
  panels: ReactNode[];
  open: (panel: ReactNode) => void;
  close: () => void;
  getCurrent: () => ReactNode;
}

const SidebarContext = createContext<ISidebarContext | null>(null);

export default SidebarContext;
