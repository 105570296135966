import { ArrowLeftIcon } from "@heroicons/react/solid";
import { ReactNode, useCallback, useContext, useState } from "react";
import Context from "./Context";
import PanelButton from "./PanelButton";

interface SidebarProps {
  className?: string;
  children?: ReactNode;
}

const Sidebar = ({ className, children }: SidebarProps) => {
  const [panels, setPanels] = useState<ReactNode[]>([]);
  const open = useCallback(
    (panel: ReactNode) => {
      setPanels([...panels, panel]);
    },
    [panels],
  );
  const close = useCallback(() => {
    panels.pop();
    setPanels([...panels]);
  }, [panels]);
  const getCurrent = useCallback(() => panels[panels.length - 1], [panels]);

  return (
    <Context.Provider value={{ panels, open, close, getCurrent }}>
      <div className={className}>
        {panels.length > 0 && (
          <>
            <div
              onClick={() => close()}
              className="flex cursor-pointer flex-row justify-end"
            >
              <ArrowLeftIcon className="mr-3 w-5" /> Back
            </div>
            <hr className="mb-3 mt-1 border-t-stone-400" />
          </>
        )}
        <div className={`${panels.length > 0 ? "hidden" : ""}`}>{children}</div>
        {panels.map((panel, index) => (
          <div
            key={index}
            className={`${panels.length === index + 1 ? "" : "hidden"}`}
          >
            {panel}
          </div>
        ))}
      </div>
    </Context.Provider>
  );
};

Sidebar.Context = Context;
Sidebar.PanelButton = PanelButton;

export default Sidebar;
