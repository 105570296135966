import { ReactNode, useContext } from "react";
import Button from "../../Button";
import Sidebar from "../Sidebar";

interface PanelButtonProps {
  className?: string;
  label?: ReactNode;
  children?: ReactNode;
}

const PanelButton = ({ className, label, children }: PanelButtonProps) => {
  const context = useContext(Sidebar.Context);

  return (
    <Button className={className} onClick={() => context?.open(children)}>
      {label}
    </Button>
  );
};

export default PanelButton;
