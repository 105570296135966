import { useCallback, useEffect, useState } from "react";
import TextInput from "../../../../../../UI/TextInput";
import UUIDInput from "../../../../../../UI/UUIDInput";

interface GigapixelModalInputProps {
  value?: PartialGigapixelModal;
  onChange?: (content: PartialGigapixelModal) => void;
}

const DEFAULT_VALUE: PartialGigapixelModal = {
  type: "gigapixel",
  title: "",
  src: "",
  text: "",
};

const GigapixelModalInput = ({ value, onChange }: GigapixelModalInputProps) => {
  const [content, setContent] = useState<PartialGigapixelModal>();
  useEffect(() => {
    setContent(value ?? DEFAULT_VALUE);
  }, [value]);

  const handleChange = useCallback(
    (update: PartialGigapixelModal) => {
      const updated: PartialGigapixelModal = { ...content, ...update };
      setContent(updated);
      onChange?.(updated);
    },
    [onChange, content],
  );

  return (
    <>
      <TextInput
        label="Title"
        value={content?.title}
        onChange={(title) => handleChange({ title })}
      />
      <UUIDInput
        value={content?.src}
        required
        onChange={(src) => handleChange({ src })}
      />
      <TextInput
        label="Text"
        value={content?.text}
        onChange={(text) => handleChange({ text })}
        textarea
      />
    </>
  );
};

export default GigapixelModalInput;
