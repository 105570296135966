const SaveButton = () => {
  return (
    <input
      type="submit"
      className="cursor-pointer rounded bg-stone-700 py-2 hover:bg-stone-600"
      value="Salva JSON"
    />
  );
};

export default SaveButton;
