import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import useStore from "../../store";
import Controls from "./Controls";
import Hotspots from "./Hotspots";
import Model from "./Model";
import Overlay from "./Overlay";

interface PanoramaProps {
  className?: string;
}

const Panorama = ({ className }: PanoramaProps) => {
  const panorama = useStore((state) => state.panorama);
  const setCameraPos = useStore((state) => state.setCameraPos);
  const events = useStore((state) => state.events);

  const handleClick = (position: [number, number, number]) => {
    events.emit("click", position);
  };

  return (
    <div className={`${className} relative`}>
      <Canvas>
        <Controls
          position={panorama.camera}
          onMove={(position) => setCameraPos(position)}
        />
        <Suspense fallback={null}>
          <Model onClick={(position) => handleClick(position)} />
        </Suspense>
        <Hotspots hotspots={panorama.hotspots} />
      </Canvas>
      <Overlay info={panorama} />
    </div>
  );
};

export default Panorama;
