import { Listbox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";

export interface OptionProps {
  children: string;
  value?: string;
}

const Option = ({ children, value }: OptionProps) => {
  return (
    <Listbox.Option
      value={value || children}
      className={({ active }) =>
        `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
          active ? "bg-stone-600" : ""
        }`
      }
    >
      {({ selected }) => (
        <>
          <span
            className={`block truncate ${
              selected ? "font-medium" : "font-normal"
            }`}
          >
            {children}
          </span>
          {selected && (
            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-stone-200">
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </Listbox.Option>
  );
};

export default Option;
