import { useEffect, useState } from "react";
import { BackSide, Texture, TextureLoader } from "three";
import useStore from "../../store";

interface ModelProps {
  onClick?: (position: [number, number, number]) => void;
}
const Model = ({ onClick }: ModelProps) => {
  const panoramaURL = useStore((state) => state.panoramaURL);
  const [texture, setTexture] = useState<Texture>();

  const handleClick = (position: [number, number, number]) => {
    onClick?.(position);
  };

  useEffect(() => {
    if (panoramaURL) {
      new TextureLoader().load(panoramaURL, setTexture);
    }
  }, [panoramaURL]);

  return texture ? (
    <mesh
      scale={[-1, 1, 1]}
      onPointerDown={({ point }) => handleClick(point.toArray())}
    >
      <sphereBufferGeometry args={[10, 60, 40]} />
      <meshBasicMaterial map={texture} side={BackSide} />
    </mesh>
  ) : (
    <></>
  );
};

export default Model;
