import { ReactComponent as Logo } from "../../icons/logo_main.svg";
import PanormaModeButton from "../PanormaModeButton";
import useStore from "../../store";

interface HeaderProps {
  className?: string;
}

const Header = ({ className = "" }: HeaderProps) => {
  const mode = useStore((state) => state.mode);
  return (
    <div
      className={`${className} flex justify-between self-center bg-stone-800 px-4`}
    >
      <div className="flex items-center gap-2">
        <Logo className="w-8" />
        <span className="text-2xl text-stone-400">
          {mode === "edit" ? "Panorama Editor" : "Panorama Preview"}
        </span>
      </div>
      <PanormaModeButton className="gap-2" />
    </div>
  );
};

export default Header;
