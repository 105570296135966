import OverlayGrid from "../../../components/Overlay/OverlayGrid";
import Player from "../../../components/Overlay/Player";
import Side from "../../../components/Overlay/Side";
import ToggleLabels from "../../../components/Overlay/ToggleLabels";
import PanoramaModeButton from "../../../../PanormaModeButton/PanoramaModeButton";
import useResourceSrcQuery from "../../../hooks/useResourceSrcQuery";
import useHotspotStore from "../../../components/Hotspots/store";

interface OverlayProps {
  panorama?: PartialPanorama;
}

const Overlay = ({ panorama }: OverlayProps) => {
  return (
    <OverlayGrid
      columns="2fr 1fr"
      rows="10vw auto 10vw"
      areas={["side . link", "side . .", "side . audio"]}
    >
      <OverlayGrid.Area area="link">
        {/* <TimeMachineLink /> */}
        <PanoramaModeButton className="button_tm group pointer-events-auto m-6  flex h-[50px] flex-row items-center gap-2 border-[1px] border-white bg-black/40 p-8 px-4 font-['Roboto'] text-lg font-semibold text-white hover:bg-white hover:text-black md:p-4" />
        {panorama?.toggleLabels && <ToggleLabels />}
      </OverlayGrid.Area>

      <OverlayGrid.Area area="audio" className="flex items-end justify-end">
        <Player />
      </OverlayGrid.Area>

      <OverlayGrid.Area area="side" className="panorama period-title">
        {panorama && <Side resource={panorama} />}
      </OverlayGrid.Area>
    </OverlayGrid>
  );
};

export default Overlay;
