import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../../icons/logo_main.svg";
import IndexedArray from "../../../utils/IndexedArray";
import ToggleSideButton from "../../Side/ToggleSideButton";
import ResourceLink from "./ResourceLink";

interface OverlayProps {
  info: PartialResourceInfo;
}

const Overlay = ({
  info: { strapline, title, title_color = "#FFF", description, links },
}: OverlayProps) => {
  const [list, setList] = useState<IndexedArray<PartialResourceLink>>([]);
  useEffect(() => {
    setList(new IndexedArray(links ?? []));
  }, [links]);
  return (
    <div className="overlay pointer-events-none absolute top-0 left-0 flex h-full w-1/3 min-w-[32rem] select-none flex-col items-start justify-start p-8">
      <div className="mb-4 flex w-full flex-row items-start">
        <Logo className="w-20 pr-4" />
        <div className="flex h-full flex-col justify-center">
          {strapline && (
            <p className="text-lg font-normal leading-6 drop-shadow-md">
              {strapline}
            </p>
          )}
          <h2
            className="font-display text-3xl font-medium uppercase leading-8 drop-shadow-md"
            style={{ color: title_color }}
          >
            {title}
          </h2>
        </div>
      </div>

      <p
        className="mb-4 pr-20 text-xl font-normal leading-6 drop-shadow-md"
        dangerouslySetInnerHTML={{ __html: description || "" }}
      ></p>

      {list?.map((link) => (
        <ResourceLink key={link.uuid} link={link} />
      ))}
      <div className="absolute bottom-1 left-0">
        <ToggleSideButton />
      </div>
    </div>
  );
};

export default Overlay;
