import GigapixelViewer from "../../GigapixelViewer";
import useGpxQuery from "../../../hooks/useGpxQuery";

interface GigapixelModalProps {
  content: GigapixelModal;
}

const GigapixelModal = ({ content: { src, text } }: GigapixelModalProps) => {
  const { data: imageUrl } = useGpxQuery(src!);

  return (
    <div className="flex grow flex-col justify-center gap-4 overflow-auto pt-4">
      {imageUrl && <GigapixelViewer tileSources={imageUrl} />}
      {text && (
        <div className="flex basis-32 flex-row justify-end gap-2 overflow-auto">
          <div
            className="modal-text overflow-y-auto text-white"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default GigapixelModal;
