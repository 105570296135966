import { ReactNode, useCallback, useEffect, useState } from "react";

interface ButtonProps {
  children?: ReactNode;
  toggle?: boolean;
  className?: string;
  onClick?: () => void;
  onActiveClick?: () => void;
  active?: boolean;
}

const Button = ({
  children,
  toggle = false,
  className,
  onClick,
  active: _active = false,
  onActiveClick,
}: ButtonProps) => {
  const [active, setActive] = useState<boolean>(_active);

  useEffect(() => {
    setActive(_active);
  }, [_active]);

  const handleClick = useCallback(() => {
    if (active) {
      onActiveClick?.();
    } else {
      onClick?.();
    }

    if (toggle) {
      setActive(!active);
    }
  }, [active, onClick, onActiveClick]);

  return (
    <div
      onClick={() => handleClick()}
      className={`${className} ${
        active
          ? "border-stone-200 bg-stone-200 text-stone-700 hover:bg-stone-400"
          : "border-stone-700 text-stone-200 hover:bg-stone-700"
      } flex cursor-pointer select-none justify-center rounded border p-2`}
    >
      {children}
    </div>
  );
};

export default Button;
