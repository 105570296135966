import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect } from "react";
import Menu from "./Menu";

interface EditorProps {
  value?: string;
  className?: string;
  onChange?: (html: string) => void;
}

const Editor = ({ value = "", className = "", onChange }: EditorProps) => {
  const editor = useEditor({
    editorProps: {
      attributes: {
        class:
          "border border-t-0 border-stone-600 rounded-b p-2 focus-visible:outline-none",
      },
    },
    extensions: [
      StarterKit.configure({
        paragraph: {
          HTMLAttributes: {
            class: "before:empty:content-['_'] before:empty:whitespace-pre",
          },
        },
      }),
      Underline,
    ],
    content: value,
  });

  useEffect(() => {
    editor?.commands.clearContent();
    editor?.commands.insertContent(value);
  }, [value, editor]);

  useEffect(() => {
    editor?.off("update");
    editor?.on("update", ({ editor }) => {
      onChange?.(editor?.getHTML() || "");
    });
  }, [editor, onChange]);

  return (
    <div className={`${className} flex flex-col`}>
      <Menu editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
};

export default Editor;
