import { useRef } from "react";
interface FileInputProps {
  accept?: string;
  onLoad?: (file: File) => void;
}

const FileInput = ({ accept = "*/*", onLoad }: FileInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = (file?: File | null) => {
    if (file instanceof File) {
      onLoad?.(file);
      if (ref.current) {
        ref.current.value = "";
      }
    }
  };

  return (
    <input
      ref={ref}
      type="file"
      accept={accept}
      onChange={({ target: { files } }) => handleChange(files?.item(0))}
    />
  );
};

export default FileInput;
