import { useCallback, useEffect, useState } from "react";
import TextInput from "../../../../../UI/TextInput";

interface LinkActionInputProps {
  value?: PartialLinkAction;
  onChange?: (action: PartialLinkAction) => void;
}

const LinkActionInput = ({ value, onChange }: LinkActionInputProps) => {
  const [action, setAction] = useState<PartialLinkAction>();
  useEffect(() => {
    setAction(value);
  }, [value]);

  const handleChange = useCallback(
    (update: PartialLinkAction) => {
      const updated: PartialLinkAction = { ...action, ...update };
      setAction(updated);
      onChange?.(updated);
    },
    [onChange, action],
  );

  return (
    <>
      <TextInput
        label="href"
        value={action?.to}
        onChange={(to) => handleChange({ to })}
        required
      />
    </>
  );
};

export default LinkActionInput;
