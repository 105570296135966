import { ReactComponent as PreviewIcon } from "../../icons/panorama mode button/eye-icon.svg";
import { ReactComponent as EditIcon } from "../../icons/panorama mode button/edit-svgrepo-com.svg";
import Button from "../UI/Button";
import useStore from "../../store";

interface PanoramaModeButtonProps {
  className?: string;
}

const PanormaModeButton = ({ className = "" }: PanoramaModeButtonProps) => {
  const mode = useStore((state) => state.mode);
  const setMode = useStore((state) => state.setMode);

  const handleClick = () => {
    setMode(mode === "preview" ? "edit" : "preview");
  };
  return (
    <Button className={`${className} bg-stone-800`} onClick={handleClick}>
      {mode === "edit" ? (
        <>
          <span className="text-base  text-stone-400">Preview</span>
          <PreviewIcon className="w-4"></PreviewIcon>
        </>
      ) : (
        <>
          <span className="text-base text-stone-400">Edit</span>
          <EditIcon className="mb-1 w-4"></EditIcon>
        </>
      )}
    </Button>
  );
};
export default PanormaModeButton;
