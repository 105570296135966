import FileInput from "../../UI/FileInput";

interface LoadButtonProps {
  onLoad?: (panorama: Panorama) => void;
}

const LoadButton = ({ onLoad }: LoadButtonProps) => {
  const readFile = (file: File) => {
    if (file) {
      let fileread: FileReader = new FileReader();
      fileread.onload = ({ target }: ProgressEvent<FileReader>) => {
        if (typeof target?.result === "string") {
          onLoad?.(JSON.parse(target.result));
        }
      };
      fileread.readAsText(file);
    }
  };

  return (
    <>
      <div>Carica JSON - Panorama</div>
      <FileInput accept="application/json" onLoad={(file) => readFile(file)} />
    </>
  );
};

export default LoadButton;
