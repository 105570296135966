import { ReactNode } from "react";
import useGpxQuery from "../../hooks/useGpxQuery";
import useHotspotStore from "../Hotspots/store";
import ActionList from "./ActionList";
import ResourceLink from "./ResourceLink";
import Title from "./Title";

interface SideProps {
  resource: PartialResourceInfo;
  children?: ReactNode;
}

const Side = ({
  resource: { strapline, title, description, links },
  children,
}: SideProps) => {
  const list_action = useHotspotStore((state) => state.list_action);
  return (
    <div
      className={`info ${
        list_action && "pointer-events-auto bg-black/80"
      } sx-col flex h-full w-1/3 min-w-[550px] flex-col items-start justify-start p-8`}
    >
      <Title strapline={strapline} title={title || ""} />
      {list_action ? (
        <ActionList action={list_action} />
      ) : (
        <>
          {description && (
            <p
              className="mb-4 pr-20 font-[Roboto] text-xl font-[400] leading-6 text-white drop-shadow-md"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          )}
          {links?.map((link, i) => (
            <ResourceLink key={`infobox-link-${i}`} link={link} />
          ))}
          {children}
        </>
      )}
    </div>
  );
};

export default Side;
