import { useCallback, useEffect, useState } from "react";
import TextInput from "../../../../../../../../UI/TextInput";
import UUIDInput from "../../../../../../../../UI/UUIDInput";

interface GalleryImageInputProps {
  className?: string;
  value?: PartialGalleryImage;
  onChange?: (image: PartialGalleryImage) => void;
}

const DEFAULT_VALUE: PartialGalleryImage = {
  src: "",
  text: "",
};

const GalleryImageInput = ({
  className,
  value,
  onChange,
}: GalleryImageInputProps) => {
  const [image, setImage] = useState<PartialGalleryImage>();
  useEffect(() => {
    setImage(value ?? DEFAULT_VALUE);
  }, [value]);

  const handleChange = useCallback(
    (update: PartialGalleryImage) => {
      const updated: PartialGalleryImage = { ...image, ...update };
      setImage(updated);
      onChange?.(updated);
    },
    [onChange, image],
  );

  return (
    <div className={className}>
      <UUIDInput value={image?.src} onChange={(src) => handleChange({ src })} />
      <TextInput
        label="Text"
        value={image?.text}
        onChange={(text) => handleChange({ text })}
        textarea
      />
    </div>
  );
};

export default GalleryImageInput;
