import useStore from "../../../store";
import Button from "../../UI/Button";

interface CameraButtonProps {
  value?: [number, number, number];
  onClick?: (camera_pos: [number, number, number]) => void;
}

const CameraButton = ({ value, onClick }: CameraButtonProps) => {
  const camera_pos = useStore((state) => state.camera_pos);

  return <Button onClick={() => onClick?.(camera_pos)}>Imposta Camera</Button>;
};

export default CameraButton;
