import Side from "./components/Side";
import PanoramaEditor from "./components/Panorama";
import PanoramaPreview from "./components/Preview/PanoramaPreview/PanoramaPreview";
import "./App.css";
import Header from "./components/Header";
import useStore from "./store";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App() {
  const side_open = useStore((state) => state.side_open);
  const mode = useStore((state) => state.mode);
  return (
    <div className={`App ${side_open ? "side-open" : ""} h-screen`}>
      {mode === "edit" ? (
        <>
          <Header className="Header" />
          <Side className="Side" />
          <PanoramaEditor className="Panorama min-w-0" />
        </>
      ) : (
        <>
          <QueryClientProvider client={queryClient}>
            <PanoramaPreview />
          </QueryClientProvider>
        </>
      )}
    </div>
  );
}

export default App;
