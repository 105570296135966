import { useEffect, useRef, useState } from "react";
import { ReactComponent as PlayIcon } from "../../../icons/player/play.svg";
import { ReactComponent as PauseIcon } from "../../../icons/player/pause.svg";
import useResourceSrcQuery from "../hooks/useResourceSrcQuery";
interface AudioPlayerProps {
  audio?: string;
}

const formatTime = (time: number) => {
  return new Date(time * 1000).toISOString().substring(14, 19);
};

const AudioPlayer = ({ audio }: AudioPlayerProps) => {
  const audioRef = useRef(new Audio());
  const { duration } = audioRef.current;
  const intervalRef = useRef<number>();

  const [playing, setPlaying] = useState(true);
  const [trackProgress, setTrackProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [totalTime, setTotalTime] = useState("00:00");
  const { data: audioSrc } = useResourceSrcQuery(audio!);

  useEffect(() => {
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (audioSrc) {
      //HACK: da risitemare adesso cerca l'oog/mp3 con una procedura molto casuale studiare lo streaming per i dash
      //TODO: if per caricare mp3 o ogg
      //mp3: _131072_48000_2.mp
      //ogg: _131072_48000_2.ogg
      let format = "_131072_48000_2.ogg";
      let audioUrl = new URL(audioSrc);
      audioRef.current.src =
        audioUrl.origin +
        "/" +
        audioUrl.pathname.split("/")[1] +
        "/" +
        audioUrl.pathname.split("/")[2] +
        "/ogg/rendition_" +
        audioUrl.pathname.split("/")[2] +
        format;
      audioRef.current.autoplay = true;
      audioRef.current.load();
      setPlaying(true);
    }
  }, [audioSrc]);

  useEffect(() => {
    if (isNaN(duration)) return;
    setTotalTime(formatTime(duration));
  }, [duration]);

  useEffect(() => {
    if (playing) {
      audioRef.current.play();
      startTimer();
    } else {
      clearInterval(intervalRef.current);
      audioRef.current.pause();
    }
  }, [playing]);

  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = window.setInterval(() => {
      if (audioRef.current.ended) {
        stop();
      } else {
        setTrackProgress(audioRef.current.currentTime);
        setCurrentTime(formatTime(audioRef.current.currentTime));
      }
    }, 100);
  };

  const stop = () => {
    setPlaying(false);
    audioRef.current.currentTime = 0;
    setTrackProgress(0);
  };

  const onScrub = (value: number) => {
    stop();
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    setPlaying(true);
  };

  return (
    <div className="flex w-full flex-row items-end bg-black/80 p-4 drop-shadow-md ">
      <button
        className="mr-4 flex h-12 w-12 items-center justify-center rounded-full bg-[#9C9770] md:h-9 md:w-9"
        onClick={() => setPlaying(!playing)}
      >
        {!playing ? (
          <PlayIcon className="h-4 w-4 md:h-3 md:w-3" />
        ) : (
          <PauseIcon className="h-4 w-4 md:h-3 md:w-3" />
        )}
      </button>
      <div className="flex grow flex-col justify-center">
        <input
          type="range"
          value={trackProgress}
          step="any"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => onScrub(+e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
        />
        <div className="flex flex-row justify-between md:mt-2">
          <span className="text-xs text-[#ffffff] md:text-[0.5rem]">
            {currentTime}
          </span>
          <span className="text-xs text-[#ffffff] md:text-[0.5rem]">
            {totalTime}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;

// * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks guida per un audioplayer
