import { ReactNode } from "react";

interface MenuButtonProps {
  active?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

const MenuButton = ({
  active = false,
  children,
  className,
  onClick,
}: MenuButtonProps) => {
  return (
    <div
      className={`${className} flex h-7 w-7 cursor-pointer items-center justify-center rounded-sm border border-stone-400 ${
        active ? "bg-stone-800 text-stone-100" : "bg-stone-700 text-stone-400"
      }`}
      onClick={() => onClick?.()}
    >
      {children}
    </div>
  );
};

export default MenuButton;
